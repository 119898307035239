.current-book {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
}

.current-book__title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
  position: relative;
}

.current-book__title:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 10%;
  transform: translateX(-50%);
  width: 40px;
  height: 3px;
  background-color: #ff4e42;
}

.current-book__container {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.current-book__card {
  background: #f5f7fa;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.current-book__image {
  width: 180px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.current-book__info {
  text-align: center;
}

.current-book__book-title {
  font-size: 18px;
  color: #333;
  margin: 10px 0 5px;
}

.current-book__author {
  font-size: 14px;
  color: #666;
  margin: 0;
}

@media (max-width: 480px) {
  .current-book {
    padding: 16px;
  }

  .current-book__image {
    width: 140px;
  }

  .current-book__book-title {
    font-size: 16px;
  }

  .current-book__author {
    font-size: 13px;
  }
} 