/* Общие стили для страницы профиля */
.profile-page {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.loading {
  text-align: center;
  padding: 50px;
  font-size: 1.5rem;
}

/* Стили для кнопки редактирования профиля */
.edit-profile-button-container {
  text-align: center;
  margin: 30px 0;
}

.edit-profile-button {
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  padding: 12px 30px;
  font-size: 1rem;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(255, 107, 107, 0.3);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.edit-profile-button:hover {
  background-color: #ff5252;
  transform: translateY(-3px);
}
.link-nfc-button {
  padding: 0.75rem 1.5rem;
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.link-nfc-button:hover {
  background-color: #45a049;
}

/* Стили для секции статистики */
.statistics-section {
  flex: 2; /* Занимает 2/3 пространства */
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.statistics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.stat-card {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 10px;
  text-align: center;
}

.stat-value {
  font-size: 2rem;
  color: #ff6b6b;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: #666;
  font-size: 1rem;
}

.reading-dates {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.reading-date {
  background-color: #ff6b6b;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

/* Медиазапросы для адаптивности */
@media (max-width: 768px) {
  .edit-profile-button {
    width: 80%;
    padding: 12px 0;
  }

  .profile-content {
    flex-direction: column;
  }

  .statistics-section,
  .current-book {
    width: 100%;
  }
}

.profile-content {
  display: flex;
  gap: 30px;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.current-book {
  flex: 1; /* Занимает 1/3 пространства */
}
