.reading-stats {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.reading-stats__title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
  position: relative;
}

.reading-stats__title:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 10%;
  transform: translateX(-50%);
  width: 40px;
  height: 3px;
  background-color: #ff4e42;
}

.reading-stats__container {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.reading-stats__card {
  background: #f5f7fa;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
}

.reading-stats__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.reading-stats__stat-item {
  text-align: center;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.reading-stats__stat-value {
  font-size: 24px;
  color: #ff4e42;
  font-weight: bold;
  margin-bottom: 8px;
}

.reading-stats__stat-label {
  font-size: 14px;
  color: #666;
}

.reading-stats__history {
  margin-top: 20px;
}

.reading-stats__subtitle {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
}

.reading-stats__dates {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.reading-stats__date {
  background-color: #ff4e42;
  color: white;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .reading-stats__grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .reading-stats {
    padding: 16px;
  }

  .reading-stats__stat-value {
    font-size: 20px;
  }

  .reading-stats__stat-label {
    font-size: 13px;
  }
}

.reading-stats__actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.reading-stats__add-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.reading-stats__add-button:hover {
  background-color: #357abD;
}

.reading-stats__modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.reading-stats__modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reading-stats__modal h3 {
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.reading-stats__date-picker {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.reading-stats__date-picker input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.reading-stats__time-inputs {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.reading-stats__time-input {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.reading-stats__time-input label {
  margin-bottom: 5px;
  font-weight: 500;
}

.reading-stats__time-input input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.reading-stats__modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.reading-stats__modal-actions button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.reading-stats__modal-actions button:first-child {
  background-color: #f2f2f2;
  color: #333;
}

.reading-stats__modal-actions button:last-child {
  background-color: #4a90e2;
  color: white;
}

.reading-stats__modal-actions button:hover {
  opacity: 0.9;
}

.reading-stats__date-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

/* Стили для нативного календаря */
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0.8;
  padding: 5px;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

.reading-stats__input-hint {
  display: block;
  font-size: 12px;
  color: #666;
  margin-top: 4px;
  font-style: italic;
} 