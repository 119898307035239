.profile-edit-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
  }
  
  .edit-profile-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  .profile-edit-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .form-control {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 1rem;
    margin-top: 5px;
  }
  
  .profile-preview-img {
    width: 150px;
    height: 150px;
    object-fit: cover; /* Это предотвратит искажение изображения */
    border-radius: 50%;
    margin-top: 10px;
  }
  

  .button-group {
    display: flex;
    justify-content: space-between;
  }
  
  .save-button {
    background-color: #ff4e42;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: #333;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .upload-hint {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 8px;
  }
  
  .upload-note {
    color: #666;
    font-style: italic;
    font-size: 0.85em;
  }
  
  .error-message {
    color: #dc3545;
    margin-top: 5px;
    font-size: 0.9em;
  }
  
  .profile-preview-img {
    max-width: 200px;
    margin-top: 10px;
    border-radius: 4px;
  }
  
  input[type="file"] {
    margin-top: 5px;
  }
  