.container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.sidebar {
  width: 300px;
  background-color: #f5f5f5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
}

.reader {
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  padding: 20px;
  position: relative;
}

.reader > div {
  height: auto !important;
  min-height: 100%;
  overflow: visible !important;
}

.addBookmark {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.addBookmark:hover {
  background-color: #0056b3;
}

.bookmarksList {
  overflow-y: auto;
}

.bookmarkItem {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.bookmarkContent {
  flex: 1;
  cursor: pointer;
}

.bookmarkContent:hover {
  background-color: #eee;
}

.bookmarkText {
  margin: 0 0 5px 0;
  font-size: 14px;
}

.bookmarkDate {
  margin: 0;
  font-size: 12px;
  color: #666;
}

.removeBookmark {
  background: none;
  border: none;
  color: #dc3545;
  font-size: 20px;
  cursor: pointer;
  padding: 0 5px;
}

.removeBookmark:hover {
  color: #c82333;
} 