.reading-calendar {
  margin-top: 30px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.reading-calendar__title {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.reading-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.reading-calendar__nav-button {
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.reading-calendar__nav-button:hover {
  background-color: #e9e9e9;
}

.reading-calendar__month-title {
  font-size: 16px;
  color: #333;
  text-transform: capitalize;
  margin: 0;
}

.reading-calendar__container {
  margin-bottom: 15px;
}

.reading-calendar__weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 5px;
}

.reading-calendar__weekday {
  text-align: center;
  font-size: 12px;
  color: #666;
  padding: 5px;
}

.reading-calendar__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.reading-calendar__day {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
}

.reading-calendar__day--empty {
  background-color: transparent;
}

.reading-calendar__day--reading {
  background-color: #ff4e42;
  color: white;
}

/* Стили для легенды */
.reading-calendar__legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.reading-calendar__legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #666;
}

.reading-calendar__legend-color {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
}

.reading-calendar__legend-color--reading {
  background-color: #ff4e42;
  border: none;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .reading-calendar {
    padding: 15px;
  }
  
  .reading-calendar__day {
    height: 25px;
    font-size: 12px;
  }
  
  .reading-calendar__weekday {
    font-size: 10px;
  }
} 